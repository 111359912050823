@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');


.apply-qpr-styles {
  .use-header-font{

  }
  .MuiButton-root, .MuiInputBase-root, .video-status, .preview-video, .MuiPaper-root {
    border-radius: 4px !important;
  }
  .MuiCard-root {
    border-radius: 0 !important;
  }

  header.MuiPaper-root {
    border-radius: 0 !important;
  }
  footer, .sticky-footer {
    border-top: solid;
    border-top-color: #B3BDC8;
    border-top-width: 0.5px;
    background-color: #FFFFFF;
  }
}
