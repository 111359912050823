@font-face {
  font-family: 'BrownStd';
  src: url('./fonts/BrownStd-BoldAlt.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'BrownStd';
  src: url('./fonts/BrownStd-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BrownStd';
  src: url('./fonts/BrownStd-RegularAlt.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BrownStd';
  src: url('./fonts/BrownStd-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}