@import './fonts';

.apply-ffc-styles {
  .use-header-font {
    font-weight: bold;
  }
  .MuiButton-root, .MuiInputBase-root, .video-status, .preview-video, .MuiPaper-root {
    border-radius: 0 !important;
  }
  .base-card-element {
    border-radius: 0 !important;
  }
}

